import {
  mdiCheckCircleOutline,
  mdiUndoVariant,
  mdiAccountClockOutline,
  mdiCloseCircleOutline,
  mdiCancel,
  mdiPlusCircleOutline
} from '@mdi/js';

export default Object.freeze({
  NEW: 'new',
  NEW_PREDICTED: 'new_predicted',
  RETURNED: 'returned',
  PENDING_AUTHORIZER: 'pending_customer_autorizer',
  PENDING_PURCHASER: 'pending_customer_purchaser',
  PENDING_APPROVAL: 'pending_approval',
  REJECTED: 'rejected',
  APPROVED: 'approved',
  CANCELLED: 'cancelled'
});

export const SubmissionStatusColor = {
  new: {
    background: 'primaryDark',
    textColor: 'white',
    icon: mdiPlusCircleOutline
  },
  new_predicted: {
    background: 'primaryDark',
    textColor: 'white',
    icon: mdiPlusCircleOutline
  },
  returned: {
    background: 'warning',
    textColor: 'textPrimary',
    icon: mdiUndoVariant
  },
  pending_approval: {
    background: 'info',
    textColor: 'textPrimary',
    icon: mdiAccountClockOutline
  },
  rejected: {
    background: 'negative',
    textColor: 'white',
    icon: mdiCloseCircleOutline
  },
  approved: {
    background: 'positive',
    textColor: 'positive',
    icon: mdiCheckCircleOutline
  },
  cancelled: {
    background: 'accent',
    textColor: 'white',
    icon: mdiCancel
  }
};
